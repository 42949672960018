import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import { Card } from "react-bootstrap"

import "./styles.scss"

const JobCard = ({
  cardName,
  cardTitle,
  cardDescription,
  cardButtonName,
  extraStyle,
}) => (
  <Card className="card" style={extraStyle}>
    <Card.Body>
      <Card.Title className="card-title">{cardTitle}</Card.Title>
      <Card.Text className="card-text">{cardDescription}</Card.Text>
      <div
        className="card-button"
        onClick={() =>
          navigate(`/career-detail/?page=${cardName}`, { state: { cardName } })
        }
      >
        <h6 className="card-button-text">{cardButtonName}</h6>
      </div>
    </Card.Body>
  </Card>
)

JobCard.propTypes = {
  cardName: PropTypes.string,
  cardTitle: PropTypes.string,
  cardDescription: PropTypes.string,
  cardButtonName: PropTypes.string,
  extraStyle: PropTypes.any,
}

JobCard.defaultProps = {
  cardName: ``,
  cardTitle: ``,
  cardDescription: ``,
  cardButtonName: ``,
}

export default JobCard
